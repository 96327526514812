<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-admin-scenario-sub-accessory-modal" title="Admin Scenario Sub Accessory Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="admin_scenario_accessory_id" class="col-form-label col-form-label-sm">Accessory <span class="custom-required">*</span></label>
              <select v-model="admin_scenario_sub_accessory.admin_scenario_accessory_id" v-validate="'required'" name="admin_scenario_accessory_id" id="admin_scenario_accessory_id" class="form-control form-control-sm" >
                <option selected disabled :value="''">Select a accessory</option>
                <option v-for="(admin_scenario_accessory, index) in admin_scenario_accessories" :key="index" :value="admin_scenario_accessory.id">{{ admin_scenario_accessory.name }}</option>
              </select>
              <div class="invalid-feedback">Accessory is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="admin_scenario_sub_accessory.name" v-validate="'required|max:255'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter name"/>
              <div class="invalid-feedback">Name is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="status" class="col-form-label col-form-label-sm">Status <span class="custom-required">*</span></label>
              <select v-model="admin_scenario_sub_accessory.status" v-validate="'required'" name="status" id="status" class="form-control form-control-sm" >
                <option value="1">Active</option>
                <option value="2">Inactive</option>
              </select>
              <div class="invalid-feedback">Status is required</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-admin-scenario-sub-accessory-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'addUpdateSubAccessory',
  props: ['admin_scenario_sub_accessory', 'btnEdit'],
  data() {
    return {
      admin_scenario_accessories: [],
      validation_errors: {},
      show: false,
      loader: false,
    }
  },
  mounted() {
    this.getCodes()
  },
  methods: {
    getCodes() {
      this.loader = true
      apiClient.get('api/admin-scenario/sub-accessory/code')
        .then(response => {
          this.loader = false
          this.admin_scenario_accessories = response.data.admin_scenario_accessories
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/admin-scenario/sub-accessories', this.admin_scenario_sub_accessory).then(response => {
            this.loader = false
            if (response.data.error) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.$emit('resetForm')
              this.$emit('getAdminScenarioSubAccessories')
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.put('api/admin-scenario/sub-accessories/' + this.admin_scenario_sub_accessory.id, this.admin_scenario_sub_accessory)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                this.$bvModal.hide('add-update-admin-scenario-sub-accessory-modal')
                this.$emit('getAdminScenarioSubAccessories')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
